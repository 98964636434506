import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { tt } from "../utils";

const SystemInfoContext = createContext<{
  name: string;
  beian: string;
}>({
  name: "",
  beian: "",
});

export function SystemInfoProvider({ children }: { children: ReactElement }) {
  const { t } = useTranslation();

  const [info, setInfo] = useState({
    name: "",
    beian: "",
  });

  useEffect(() => {
    let info = {
      name: "Mallboost",
      beian: "粤ICP备2024201511号-1",
    };
    if (window.location.hostname.indexOf("mallboost") !== -1) {
    } else {
      info = {
        name: "Luckiads",
        beian: "粤ICP备2024201511号-2",
      };
    }
    setInfo(info);

    document.title = `${tt(t, info.name)}网`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SystemInfoContext.Provider value={info}>
      {children}
    </SystemInfoContext.Provider>
  );
}

export function useSystemInfo() {
  const info = useContext(SystemInfoContext);
  return info;
}
