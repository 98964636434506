import { Button, Menu, MenuProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  AuditOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FileSyncOutlined,
  MoneyCollectOutlined,
  PicRightOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { useProfile } from "../hooks/useAuth";
import { ScreenControlProvider } from "../hooks/useDeviceControl";
import { SystemInfoProvider, useSystemInfo } from "../hooks/useSystemInfo";
import { tt } from "../utils";
import { TopUserMenu } from "./W3Layout";

type MenuItem = Required<MenuProps>["items"][number];

export enum USER_TYPE {
  ADMIN = 1,
  OPERATOR,
  BC,
  FINANCE,
}

export const topbarBgColors: any = {
  [USER_TYPE.ADMIN]: "bg-blue-100",
  [USER_TYPE.OPERATOR]: "bg-purple-100",
  [USER_TYPE.BC]: "bg-orange-100",
  [USER_TYPE.FINANCE]: "bg-gray-100",
};

export const USER_TYPES: any = {
  [USER_TYPE.ADMIN]: "admin",
  [USER_TYPE.OPERATOR]: "operator",
  [USER_TYPE.BC]: "bc",
  [USER_TYPE.FINANCE]: "finance",
};

export default function ConsoleLayout() {
  return (
    <SystemInfoProvider>
      <ScreenControlProvider>
        <>
          <Content />
        </>
      </ScreenControlProvider>
    </SystemInfoProvider>
  );
}

function Content() {
  const { t } = useTranslation();
  const info = useSystemInfo();

  const profile = useProfile();

  // useEffect(() => {
  //   if (profile.type)
  //     document.title = `${document.title}/${USER_TYPES[profile.type]}`;
  // }, [profile.type]);

  if (!profile._id) return null;

  return (
    <>
      <div
        className={`absolute w-full flex justify-between items-center shadow z-10 ${
          topbarBgColors[profile.type]
        }`}
        style={{
          height: 60,
          gridTemplateColumns: "255px auto",
        }}
      >
        <Link to="/" className="px-4">
          <Button type="link">{tt(t, info.name)}</Button>
        </Link>
        <div></div>

        <div
          className="h-6 w-px bg-gray-200 lg:hidden"
          aria-hidden="true"
        ></div>
        <div className="flex gap-x-4 self-stretch lg:gap-x-6 px-6">
          <div className="flex items-center gap-x-4 lg:gap-x-6">
            {/* <LanguageSelector /> */}
            <TopUserMenu profile={profile} />
          </div>
        </div>
      </div>

      <div
        className="grid h-full"
        style={{
          gridTemplateColumns: "256px auto",
        }}
      >
        <div
          className="overflow-auto border-0 border-r border-solid border-zinc-200"
          style={{
            backgroundColor: "rgba(255,255,255,1)",
            marginTop: 60,
          }}
        >
          <Sider profile={profile} />
        </div>
        <main style={{ marginTop: 60 }}>
          <Outlet context={{ profile }} />
        </main>
      </div>
    </>
  );
}

function getMenus(profile: any, t: any) {
  const ADMIN = [
    {
      label: tt(t, "Traffic", "Monitoring"),
      key: "sub1",
      icon: (<DashboardOutlined />) as any,
      children: undefined,
      type: undefined, //"group",
    },
    {
      label: tt(t, "System", "Management"),
      key: "sub2",
      icon: (<SettingOutlined />) as any,
      children: [
        {
          label: tt(t, "Account", "Management"),
          key: "accountManagement",
        },
      ],
      type: undefined, //"group",
    },
  ];
  const OPERATOR: MenuItem[] = [
    {
      label: tt(t, "BC", "Management"),
      key: "sub1",
      icon: (<AuditOutlined />) as any,
      children: [
        {
          label: tt(t, "Account", "Management"),
          key: "accountManagement",
        },
        {
          label: tt(t, "OpenAccount", "Request"),
          key: "enterpriseCertification",
        },
        {
          label: tt(t, "Company", "List"),
          key: "company",
        },
        {
          label: tt(t, "Balance", "Management"),
          key: "balanceManagement",
        },
        {
          label: tt(t, "Credit", "Management"),
          key: "creditManagement",
        },
      ],
      type: undefined, //"group",
    },

    {
      label: tt(t, "Advertise", "Traffic"),
      key: "sub2",
      icon: (<PicRightOutlined />) as any,
      children: [
        {
          label: tt(t, "Traffic", "Statistics"),
          key: "sub22",
        },
        {
          label: tt(t, "Advertise", "Account"),
          key: "advertiseAccount",
        },
        {
          label: tt(t, "Identity", "List"),
          key: "identityList",
        },
        {
          label: tt(t, "Pixel", "List"),
          key: "pixelList",
        },
        {
          label: tt(t, "Campaign"),
          key: "campaign",
        },
        {
          label: tt(t, "Advertise", "Group"),
          key: "advertisingGroup",
        },

        {
          label: tt(t, "Video", "List"),
          key: "videoList",
        },

        {
          label: tt(t, "Music", "List"),
          key: "musicList",
        },
        {
          label: tt(t, "Image", "List"),
          key: "imageList",
        },
        {
          label: tt(t, "Partner", "List"),
          key: "partnerList",
        },
        {
          label: tt(t, "Shop", "List"),
          key: "shopList",
        },
        {
          label: tt(t, "First Party", "Shop", "List"),
          key: "shop1List",
        },
        {
          label: tt(t, "Product", "List"),
          key: "productList",
        },
      ],
      type: undefined, //"group",
    },
    {
      label: tt(t, "Media", "Management"),
      key: "sub3",
      icon: (<DatabaseOutlined />) as any,
      children: [
        {
          label: tt(t, "Media", "Management"),
          key: "media",
        },
      ],
      type: undefined, //"group",
    },
  ];

  const BC: MenuItem[] = [
    {
      label: tt(t, "Certification", "Request"),
      key: "sub1",
      icon: (<AuditOutlined />) as any,
      children: [
        {
          label: tt(t, "OpenAccount", "Request"),
          key: "enterpriseCertification",
        },
        {
          label: tt(t, "Company", "List"),
          key: "company",
        },
      ],
      type: undefined, //"group",
    },
    ...(profile.ttr
      ? [
          {
            label: tt(t, "Advertise", "Traffic"),
            key: "sub2",
            icon: (<PicRightOutlined />) as any,
            children: [
              {
                label: tt(t, "Publish", "Advertise"),
                key: "advertisingPublish",
              },
              {
                label: tt(t, "Advertise", "List"),
                key: "adList",
              },
              {
                label: tt(t, "Traffic", "Statistics"),
                key: "trafficStatistics",
              },
            ],
            type: undefined, //"group",
          },

          {
            label: tt(t, "Data", "Sync"),
            key: "sub3",
            icon: (<FileSyncOutlined />) as any,
            children: [
              {
                label: tt(t, "BC", "List"),
                key: "bcList",
              },
              {
                label: tt(t, "Advertise", "Account"),
                key: "advertiseAccount",
              },
              {
                label: tt(t, "Identity", "List"),
                key: "identityList",
              },
              {
                label: tt(t, "Pixel", "List"),
                key: "pixelList",
              },
              {
                label: tt(t, "Campaign"),
                key: "campaign",
              },
              {
                label: tt(t, "Advertise", "Group"),
                key: "advertisingGroup",
              },

              {
                label: tt(t, "Video", "List"),
                key: "videoList",
              },

              {
                label: tt(t, "Music", "List"),
                key: "musicList",
              },
              {
                label: tt(t, "Image", "List"),
                key: "imageList",
              },
              {
                label: tt(t, "Advertise", "List"),
                key: "advertisingList",
              },
              {
                label: tt(t, "Partner", "List"),
                key: "partnerList",
              },
              {
                label: tt(t, "Shop", "List"),
                key: "shopList",
              },
              {
                label: tt(t, "First Party", "Shop", "List"),
                key: "shop1List",
              },
              {
                label: tt(t, "Product", "List"),
                key: "productList",
              },
            ],
            type: undefined, //"group",
          },

          {
            label: tt(t, "Media", "Management"),
            key: "sub4",
            icon: (<DatabaseOutlined />) as any,
            children: [
              {
                label: tt(t, "Media", "Management"),
                key: "media",
              },
            ],
            type: undefined, //"group",
          },
        ]
      : []),

    {
      label: tt(t, "Expense", "Center"),
      key: "sub5",
      icon: (<MoneyCollectOutlined />) as any,
      children: [
        {
          label: tt(t, "Balance", "Management"),
          key: "balanceManagement",
        },
        {
          label: tt(t, "Reconciliation", "Management"),
          key: "g",
        },
      ],
      type: undefined, //"group",
    },
  ];

  const FINANCE: MenuItem[] = [
    {
      label: tt(t, "BC", "Management"),
      key: "sub1",
      icon: (<AuditOutlined />) as any,
      children: [
        {
          label: tt(t, "Balance", "Management"),
          key: "balanceManagement",
        },
        {
          label: tt(t, "Credit", "Management"),
          key: "creditManagement",
        },
      ],
      type: undefined, //"group",
    },
    {
      label: tt(t, "Financial", "Management"),
      key: "sub2",
      icon: (<PicRightOutlined />) as any,

      type: undefined, //"group",
    },
  ];

  const menus: any = {
    [USER_TYPE.ADMIN]: ADMIN,
    [USER_TYPE.OPERATOR]: OPERATOR,
    [USER_TYPE.BC]: BC,
    [USER_TYPE.FINANCE]: FINANCE,
  };

  const rootSubmenuKeys: any = {
    [USER_TYPE.ADMIN]: ["sub1", "sub2"],
    [USER_TYPE.OPERATOR]: ["sub1", "sub2", "sub3"],
    [USER_TYPE.BC]: ["sub1", "sub2", "sub3", "sub4", "sub5"],
    [USER_TYPE.FINANCE]: ["sub1", "sub2"],
  };

  return { menus, rootSubmenuKeys };
}

function Sider({ profile }: { profile: any }) {
  const nav = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { menus, rootSubmenuKeys } = useMemo(() => {
    return getMenus(profile, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (
      latestOpenKey &&
      rootSubmenuKeys[profile.type].indexOf(latestOpenKey!) === -1
    ) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  function menuSelect(key: string) {
    switch (key) {
      case "company":
        return "/console/company";

      case "advertisingPublish":
        return "/console/advertise/publish";
      case "trafficStatistics":
        return "/console/advertise/publish/report";
      case "adList":
        return "/console/advertise/publish/list";

      case "enterpriseCertification":
        return "/console/verify/company";
      case "accountManagement":
        return "/console/verify/account";
      case "balanceManagement":
        return "/console/balance";

      case "creditManagement":
        return "/console/credit";
      case "media":
        return "/console/media";

      case "bcList":
        return "/console/advertise/sync/bc";
      case "advertiseAccount":
        return "/console/advertise/sync/advertiser";
      case "advertisingList":
        return "/console/advertise/sync/list";

      case "advertisingGroup":
        return "/console/advertise/sync/group";
      case "campaign":
        return "/console/advertise/sync/campaign";
      case "identityList":
        return "/console/advertise/sync/identity";
      case "pixelList":
        return "/console/advertise/sync/pixel";
      case "videoList":
        return "/console/advertise/sync/video";
      case "musicList":
        return "/console/advertise/sync/music";
      case "imageList":
        return "/console/advertise/sync/image";

      case "partnerList":
        return "/console/advertise/sync/partner";
      case "shopList":
        return "/console/advertise/sync/shop";
      case "shop1List":
        return "/console/advertise/sync/shop1";

      case "productList":
        return "/console/advertise/sync/product";

      default:
        break;
    }
  }

  function findKey(menus: any[], url: string) {
    for (const menu of menus) {
      const { key } = menu;
      if (menuSelect(key) === url) return key;
      const subKey = findKey(menu.children || [], url);
      if (subKey) return key;
    }
    return null;
  }

  function findSelectedKey(menus: any[], url: string) {
    for (const menu of menus) {
      const { key } = menu;
      if (menuSelect(key) === url) return key;
      const subKey = findKey(menu.children || [], url);
      if (subKey) return subKey;
    }
    return null;
  }

  useEffect(() => {
    const ms = menus[profile.type];
    const key = findKey(ms, pathname);
    if (key) setOpenKeys([key]);

    const selectedKey = findSelectedKey(ms, pathname);
    if (selectedKey) setSelectedKeys([selectedKey]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.type, pathname]);

  if (!profile.type) return null;
  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={{
        borderInlineEndWidth: 0,
        backgroundColor: "transparent",
      }}
      items={menus[profile.type]}
      onSelect={(item) => {
        const url = menuSelect(item.key);
        if (url) nav(url);
      }}
      selectedKeys={selectedKeys}
    />
  );
}
