import axios, { AxiosResponse } from "axios";

const Axios = axios.create();
Axios.interceptors.response.use(
  (res: AxiosResponse) => {
    return res;
  },
  (err: any) => {
    if (err.code === "ERR_NETWORK") {
      const event = new CustomEvent("AxiosMessageEvent", {
        detail: {
          action: "errorNotify",
          status: err?.response?.status,
          messageText: "ERR_NETWORK",
        },
      });
      window.dispatchEvent(event);
      return Promise.resolve({ code: -1, messageText: "ERR_NETWORK" });

      // return Promise.reject(err);
    }
    // 这里用来处理http常见错误，进行全局提示
    let messageText = "";
    switch (err?.response?.status) {
      case 400:
        messageText = "请求参数错误(400)";
        break;
      case 401:
        messageText = "未授权，请重新登录(401)";
        // 这里可以做清空storage并跳转到登录页的操作
        // localStorage.removeItem("accessToken");
        window.location.href = `/login?redirect=${encodeURIComponent(
          window.location.href
        )}`;
        // message.error("未授权，请重新登录");
        break;
      case 403:
        messageText = "拒绝访问(403)";
        break;
      case 404:
        messageText = "请求路径出错(404)";
        break;
      case 408:
        messageText = "请求超时(408)";
        break;
      case 500:
        messageText = "服务器错误(500)";
        break;
      case 501:
        messageText = "服务未实现(501)";
        break;
      case 502:
        messageText = "网络错误(502)";
        break;
      case 503:
        messageText = "服务不可用(503)";
        break;
      case 504:
        messageText = "网络超时(504)";
        break;
      case 505:
        messageText = "HTTP版本不受支持(505)";
        break;
      default:
        messageText = `连接出错(${err?.response?.status})!`;
    }
    if (err?.response) err.response.statusText = messageText;
    // 这里错误消息可以使用全局弹框展示出来
    // 比如element plus 可以使用 ElMessage
    // ElMessage({
    //   showClose: true,
    //   message: `${message}，请检查网络或联系管理员！`,
    //   type: "error",
    // });
    // 这里是AxiosError类型，所以一般我们只reject我们需要的响应即可

    const event = new CustomEvent("AxiosMessageEvent", {
      detail: {
        action: "errorNotify",
        status: err?.response?.status,
        messageText,
      },
    });
    window.dispatchEvent(event);
    return Promise.resolve({ code: -1, messageText });
    // return Promise.reject(err.response);
  }
);

export default Axios;
